import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Label } from '../components/Label'
import Image from '../components/Image'
import styletron from 'gatsby-plugin-styletron'
import { Palette, Spacing } from '../theme'

const FirmPage = () => {
    const css = styletron().css
    return (
        <Layout pageTitle='Sipp Law Firm'>
            <SEO title='Home' keywords={[`Bradley Sipp`, `Contact`, `Attorney`]} />

            <div
                className={css({
                    paddingLeft: Spacing.base,
                    paddingRight: Spacing.base,
                    paddingBottom: Spacing.base,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '600px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    '@media screen and (max-width: 680px)': {
                        flexDirection: 'column',
                    },
                })}>
                <Label variant='title2' className={css({ paddingTop: Spacing.base })}>
                    Family Law
                </Label>
                <Label>
                    Bradley Sipp has a diverse Family Law practice from the initial divorce and/or child custody process to post-decree
                    modifications and appeals. The firm also handles paternity, guardianship, adoption, restraining order, and dependency
                    and neglect cases, as well as cases involving out-of-state relocation, grandparent's rights, and same sex relationships.
                </Label>
                <Label variant='title2' className={css({ paddingTop: Spacing.base })}>
                    Criminal Law
                </Label>
                <Label>
                    If you or someone you know has been arrested or charged with a crime in Eastern Nebraska, the anxiety and stress can be
                    overwhelming. It is crucial to get legal representation from a lawyer who will fight for you, educate you about the
                    criminal defense process, and relieve your stress. Even the least serious criminal charge can have a significant impact
                    on your future. At the Law Offices of Bradley A. Sipp. P.C., L.L.O., we're ready to assist you with your criminal case.
                </Label>
                <Label variant='title2' className={css({ paddingTop: Spacing.base })}>
                    Business Law
                </Label>
                <Label>
                    At The Law Offices of Bradley A. Sipp, P.C., L.L.O. we litigate matters of all descriptions, including breach of
                    contract, fraud, personal injury and other types of business cases. Whether in county, district, or Federal court, or in
                    front of arbitrators or mediators, we are willing to fight hard for you. If you are facing a litigation issue, call us.
                </Label>
            </div>
        </Layout>
    )
}

export default FirmPage
